var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width",
      attrs: { color: "light_gray", tile: "", elevation: "0" },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12", lg: "10" } },
            [
              _c(
                "v-form",
                { ref: "changePasswordForm" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "gray--text font-weight-bold text-body-1 text-capitalize",
                          },
                          [_vm._v(_vm._s(_vm.$t("label.changePassword")))]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4 pa-0",
                              attrs: {
                                text: "",
                                "min-width": "0",
                                ripple: false,
                                width: "auto",
                                height: "auto",
                                "min-height": "0",
                                plain: "",
                              },
                              on: { click: _vm.submitChangePassword },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "unbox_primary--text text-capitalize font-weight-bold text-body-1 cursor-pointer",
                                },
                                [_vm._v(_vm._s(_vm.$t("label.update")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-block text-body-2 unbox_secondary--text mt-4",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("label.changePasswordMessage"))
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("app-form-field", {
                                    attrs: {
                                      label: _vm.$t("label.currentPassword"),
                                      type: "password",
                                      placeholder: _vm.$t(
                                        "label.currentPassword"
                                      ),
                                      rules: _vm.$validation.passwordRules(),
                                    },
                                    model: {
                                      value:
                                        _vm.changePasswordForm.currentPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.changePasswordForm,
                                          "currentPassword",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "changePasswordForm.currentPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", {
                                staticClass: "pa-0 ma-0",
                                attrs: { cols: "12" },
                              }),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("app-form-field", {
                                    attrs: {
                                      label: _vm.$t("label.newPassword"),
                                      type: "password",
                                      placeholder: _vm.$t("label.newPassword"),
                                      rules: _vm.$validation.passwordRules(),
                                    },
                                    model: {
                                      value: _vm.changePasswordForm.newPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.changePasswordForm,
                                          "newPassword",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "changePasswordForm.newPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", {
                                staticClass: "pa-0 ma-0",
                                attrs: { cols: "12" },
                              }),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("app-form-field", {
                                    attrs: {
                                      label: _vm.$t("label.newConfirmPassword"),
                                      type: "password",
                                      placeholder: _vm.$t(
                                        "label.newConfirmPassword"
                                      ),
                                      rules:
                                        _vm.$validation.confirmPasswordRules(
                                          _vm.changePasswordForm.newPassword,
                                          _vm.changePasswordForm
                                            .newConfirmPassword
                                        ),
                                    },
                                    model: {
                                      value:
                                        _vm.changePasswordForm
                                          .newConfirmPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.changePasswordForm,
                                          "newConfirmPassword",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "changePasswordForm.newConfirmPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "pa-0 ma-0",
                        attrs: { cols: "12" },
                      }),
                      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "text-body-2 unbox_secondary--text",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "label.createPasswordRequirementMessage"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("div", [
                            _c("ul", [
                              _c(
                                "li",
                                {
                                  class:
                                    _vm.changePasswordForm.newPassword.length >
                                      0 &&
                                    _vm.changePasswordForm.newPassword.length >=
                                      8
                                      ? "success--text"
                                      : "error--text",
                                },
                                [
                                  _c("span", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "validationMessage.passwordRulesMinimumLength"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class:
                                    _vm.changePasswordForm.newPassword.length >
                                      0 &&
                                    _vm.changePasswordForm.newPassword.length <=
                                      20
                                      ? "success--text"
                                      : "error--text",
                                },
                                [
                                  _c("span", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "validationMessage.passwordRulesMaximumLength"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class:
                                    _vm.regex.ATLEAST_ONE_UPPERCASE_LETTER.test(
                                      _vm.changePasswordForm.newPassword
                                    )
                                      ? "success--text"
                                      : "error--text",
                                },
                                [
                                  _c("span", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "validationMessage.passwordRulesUppercaseLetter"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class:
                                    _vm.regex.ATLEAST_ONE_LOWERCASE_LETTER.test(
                                      _vm.changePasswordForm.newPassword
                                    )
                                      ? "success--text"
                                      : "error--text",
                                },
                                [
                                  _c("span", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "validationMessage.passwordRulesLowercaseLetter"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class: _vm.regex.ATLEAST_ONE_DIGIT.test(
                                    _vm.changePasswordForm.newPassword
                                  )
                                    ? "success--text"
                                    : "error--text",
                                },
                                [
                                  _c("span", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "validationMessage.passwordRulesDigit"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class:
                                    _vm.regex.ATLEAST_ONE_SPECIAL_CHARACTER.test(
                                      _vm.changePasswordForm.newPassword
                                    )
                                      ? "success--text"
                                      : "error--text",
                                },
                                [
                                  _c("span", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "validationMessage.passwordRulesSpecialCharacter"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("v-col", {
                        staticClass: "pa-0 ma-0",
                        attrs: { cols: "12" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }